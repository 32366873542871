import { Component } from '@angular/core';
import { FieldType, FormlyModule } from '@ngx-formly/core';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { MarkdownModule } from 'ngx-markdown';

@Component({
  selector: 'formly-object-type',
  template: `
    <nz-card [nzTitle]="titleTemplate" nzSize="small" [style.borderRadius]="0">
      <ng-template #descriptionTemplate>
        @if (to.description) {
          <markdown>
            {{ to.description }}
          </markdown>
        }
      </ng-template>

      <ng-template #titleTemplate>
        {{ to.label }}

        @if (to.description) {
          <i
            nz-icon
            nzType="info-circle"
            style="color: gray; margin-right: 10px"
            nz-tooltip
            [nzTooltipTitle]="descriptionTemplate"
            [nzTooltipColor]="'dimgray'"
            nzTooltipPlacement="top"
            [nzTooltipMouseEnterDelay]="0.5"></i>
        }
      </ng-template>

      @if (showError && formControl.errors) {
        <div class="alert alert-danger" role="alert">
          <formly-validation-message [field]="field" />
        </div>
      }

      @for (f of field.fieldGroup; track f) {
        <formly-field [field]="f" />
      }
    </nz-card>
  `,
  imports: [NzCardModule, MarkdownModule, NzButtonModule, NzIconModule, NzToolTipModule, FormlyModule]
})
export class ObjectTypeComponent extends FieldType {}
