import { Component } from '@angular/core';
import { FieldType, FormlyModule } from '@ngx-formly/core';
import { NzCardModule } from 'ng-zorro-antd/card';

@Component({
  selector: 'formly-multi-schema-type',
  template: `
    <nz-card [nzTitle]="to.label" nzType="inner">
      <div class="card-body">
        @if (to.description) {
          <p>{{ to.description }}</p>
        }
        @if (showError && formControl.errors) {
          <div class="alert alert-danger" role="alert">
            <formly-validation-message [field]="field" />
          </div>
        }
        @for (f of field.fieldGroup; track f) {
          <formly-field [field]="f" />
        }
      </div>
    </nz-card>
  `,
  imports: [NzCardModule, FormlyModule]
})
export class MultiSchemaTypeComponent extends FieldType {}
